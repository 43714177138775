@import "variables";
@import "colors/white";
@import "util";
@import "mixins";
@import "extend";
@import "fonts";

// global-reset()

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
  border-top: 2px solid $color-text;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  height: 100%;
  background-color: $color-background;
  color: $color-text;
  font-display: swap;
  font-weight: 400;
  font-size: $font-size;
  font-family: $font-family-body;
  line-height: $line-height;
  text-rendering: geometricPrecision;
  flex: 1;

  @include antialias();

  @extend $base-style !optional;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-wrap: break-word;

  p {
    @include hyphens(auto);
  }

  code {
    @include hyphens(manual);
  }

  a {
    color: $color-text;
    text-decoration: none;

    @include underline(5px, $color-text);

    &:hover {
      background-image: linear-gradient(transparent, transparent 4px, $color-link 4px, $color-link);
    }
  }

  a.icon {
    background: none;

    &:hover {
      color: $color-link;
    }
  }

  h1 a,
  .h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    background: none;
    color: inherit;
    text-decoration: none;
  }

  h1 a:hover,
  .h1 a:hover,
  h2 a:hover,
  h3 a:hover,
  h4 a:hover,
  h5 a:hover,
  h6 a:hover {
    @include underline(6px, $color-link);
  }

  h6 {
    a {
      background: none;
      color: inherit;
      text-decoration: none;
    }
  }

  h6 {
    a:hover {
      @include underline(6px, $color-link);
    }
  }
}

@media (min-width: 540px) {
  .image-wrap {
    flex-direction: row;
    margin-bottom: 2rem;

    .image-block {
      flex: 1 0 35%;
      margin-right: 2rem;
    }

    p {
      flex: 1 0 65%;
    }
  }
}

.max-width {
  max-width: $page-width;
}

@media (max-width: 480px) {

  // smaller margins at smaller screen widths
  .px3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .my4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 480px) {
  p {
    text-align: justify;
  }
}

@import "partial/header";
@import "partial/post/actions_desktop";
@import "partial/post/actions_mobile";
@import "partial/index";
@import "partial/article";
@import "partial/archive";
@import "partial/comments";
@import "partial/footer";
@import "partial/pagination";
@import "partial/search";
@import "partial/tags";
@import "partial/tooltip";
@import "partial/categories";

pre {
  overflow-x: auto;
  padding: 15px 15px 10px 15px;
  border: 1px dotted $color-border;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  font-size: 13px;
  font-family: $font-family-mono;
  line-height: 22px;
  position: relative;

  .code-copy-btn {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    border-radius: 0 2px;
    padding: 0;
    font-family: "JetBrains Mono", monospace;
    font-weight: 800;
    font-size: 0.9em;
    line-height: 1.7;
    color: #fff;
    background-color: #8c8c8c;
    min-width: 60px;
    text-align: center;
    cursor: pointer;
    letter-spacing: 0em;
  }

  .code-copy-btn:hover {
    background-color: #666;
    color: #2bbc8a;
  }

  code {
    display: block;
    padding: 0;
    border: none;
  }
}

code {
  font-family: $font-family-mono;
  padding: 0 5px;
  border: 1px dotted $color-border;
  border-radius: 2px;
  -webkit-border-radius: 2px;
}

.highlight {

  &>div {
    border-radius: 2px;
    -webkit-border-radius: 2px;
  }

  pre {
    border: none;
    background: none;
  }

  table {

    pre {
      margin-top: 0;
    }

    td:first-child {
      pre {
        padding-right: 0;
      }
    }

    td:last-child {
      pre {
        padding-left: 0;
      }
    }
  }
}

.blog-single-desc {
  font-size: 0.8em;
  color: #888;
  /* 更浅的颜色 */
  margin-bottom: 1rem;
  /* 下方留一些间距 */
}

.separator {
  position: relative;
  margin-top: 0.5rem;
  height: 1px;
  width: 68%;
  background-color: currentColor;
  /* 使用当前颜色 */
}

.separator:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  background-image: linear-gradient(to right, transparent 0%, currentColor 50%, transparent 100%);
  background-size: 20px 20px;
  background-position: 0 0;
  background-repeat: repeat-x;
}

/* 随机颜色 */
.random-color {
  color: var(--random-color);
}

/* 设置随机颜色的变量 */
:root {
  --random-color: hsl(var(--random-hue), 50%, 50%);
}

/* 生成随机色调值 */
:root {
  --random-hue: calc(360deg * random());
}

article .content>blockquote {
  position: relative;
  padding: 15px 20px;
  /* 给左侧绿色竖线留出空间 */
  margin: 10px 0;
  /* 根据需要调整上下外边距 */
  border-left: 4px solid green;
  /* 创建左侧绿色竖线 */
  background-color: #f9f9f9;
  /* 可选：给引用块添加背景颜色 */
}

article .content>blockquote::before {
  content: "\1F4AC";
  /* 可选：添加引号图标或其他符号作为装饰 */
  position: absolute;
  left: -10px;
  /* 调整图标位置 */
  top: 5px;
  /* 根据文本高度调整图标垂直位置 */
  font-size: 1.2em;
  /* 根据需要调整图标的大小 */
}